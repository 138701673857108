<template>
  <div class="newsletter">
    <div v-if="isSuccess" class="newsletter__success">
      <div class="content has-text-centered">
        <h2>
          Thank you for sigining up. <br />
          You are in the draw – good luck!
        </h2>

        <h3>We’ll be in touch with the winners next week.</h3>
      </div>

      <div class="newsletter__success-progress">
        <svg viewBox="0 0 48 48">
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
      </div>
    </div>
    <template v-else>
      <header class="newsletter__header has-marg-bottom-m has-text-centered">
        <h1 class="newsletter__header-title title is-2 has-marg-bottom-xs">GO IN THE DRAW TO WIN*</h1>
        <h2 class="newsletter__header-subtitle title is-3">1 of 3 $150 vouchers</h2>
      </header>
      <div class="newsletter__content has-marg-bottom-xl">
        <div class="content has-text-centered">
          <p>Join the Nelly Boo Family to enter the draw.</p>
          <p>You’ll also enjoy:</p>
          <ul>
            <li>5% discount off your first and future orders</li>
            <li>The latest news and offers from Nelly Boo</li>
          </ul>
        </div>
      </div>
      <div class="newsletter__main">
        <form action="" class="newsletter__form ui-form" novalidate @submit.prevent="subscribe">
          <div class="newsletter__fields ui-form__fields columns is-multiline">
            <div class="column is-6">
              <div class="newsletter__field ui-form__field">
                <label class="newsletter__field-label ui-form__label is-sr-only">First Name</label>
                <input
                  class="input ui-form__input"
                  name="contact[first_name]"
                  autocomplete="none"
                  type="text"
                  spellcheck="false"
                  placeholder="First Name"
                  maxlength="30"
                  v-model.trim="fields.fname"
                  required
                />
                <div v-if="v$.fields.fname.$error" class="ui-form__error">Please enter your first name</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="newsletter__field ui-form__field">
                <label class="newsletter__field-label ui-form__label is-sr-only">Last Name</label>
                <input
                  class="input ui-form__input"
                  name="contact[last_name]"
                  autocomplete="none"
                  type="text"
                  spellcheck="false"
                  placeholder="Last Name"
                  v-model.trim="fields.lname"
                  maxlength="30"
                  required
                />
                <div v-if="v$.fields.lname.$error" class="ui-form__error">Please enter your last name</div>
              </div>
            </div>
            <div class="column is-12">
              <div class="newsletter__field ui-form__field">
                <label class="newsletter__field-label ui-form__label is-sr-only">Email Address</label>
                <input
                  class="input ui-form__input"
                  name="contact[email]"
                  autocomplete="none"
                  type="text"
                  spellcheck="false"
                  placeholder="Email Address"
                  v-model.trim="fields.email"
                  required
                />
                <div v-if="v$.fields.email.$error" class="ui-form__error">Please enter a valid email address</div>
              </div>
            </div>
            <div class="column is-12 has-text-centered">
              <button class="button is-dark is-wide" type="submit">{{ isLoading ? 'Signing Up...' : 'Sign Up' }}</button>
            </div>

            <template v-if="isError && errorMessage">
              <div class="newsletter__error">
                <p>{{ errorMessage }}</p>
              </div>
            </template>

          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import queryString from 'query-string';
import jsonp from 'jsonp';

const USER_ID = '0b1ccd41c209fc000c4ab1216';
const LIST_ID = 'd138742155';
const SUBSCRIBE_URL = 'https://nellyboo.us5.list-manage.com/subscribe/post-json';
const RESET_TIMEOUT = 4000;
const SUBSCRIBE_TAGS = '4165233';

const rules = computed(() => ({
  fields: {
    email: { email, required },
    fname: { required },
    lname: { required },
  },
}));

const fields = reactive({
  email: '',
  fname: '',
  lname: '',
});

const isLoading = ref(false);
const isSuccess = ref(false);
const isError = ref(false);
const errorMessage = ref('');

const v$ = useVuelidate(rules, { fields }, { $lazy: true });

const subscribe = async () => {
  if (isLoading.value) {
    return;
  }

  const isValid = await v$.value.$validate();
  if (!isValid) {
    return;
  }

  isSuccess.value = false;
  isError.value = false;
  errorMessage.value = '';
  isLoading.value = true;

  const data = queryString.stringify({
    u: USER_ID,
    id: LIST_ID,
    EMAIL: fields.email,
    FNAME: fields.fname,
    LNAME: fields.lname,
    tags: SUBSCRIBE_TAGS,
  });

  jsonp(`${SUBSCRIBE_URL}?${data}`, { param: 'c' }, onResponse);
};

const onResponse = (error, data) => {
  isLoading.value = false;
  try {
    if (error) {
      onError(error, false);
      return;
    }
    if (data && data.result === 'error') {
      onError(data.msg, true);
      return;
    }
    onSuccess();
  } catch (err) {
    onError(err.message);
  }
};

const onSuccess = () => {
  isLoading.value = false;
  isSuccess.value = true;
  isError.value = false;
  errorMessage.value = false;
  setTimeout(() => {
    reset();
  }, RESET_TIMEOUT);
};

const onError = (message = '', format = false) => {
  let msg = message || 'Something went wrong. Please try again.';
  if (format) {
    msg = formatErrorMessage(msg);
  }
  isError.value = true;
  isSuccess.value = false;
  errorMessage.value = msg;
};

const reset = () => {
  isLoading.value = false;
  isSuccess.value = false;
  isError.value = false;
  fields.email = '';
  fields.fname = '';
  fields.lname = '';
  v$.value.$reset();
};

const formatErrorMessage = (message) => {
  return message.replace('0 - ', '');
};
</script>
