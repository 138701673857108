<template>
  <div class="header">
    <div class="header__inner container">
      <div class="header__logo">
        <svg width="358" height="343" viewBox="0 0 358 343" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M63.7498 35.54C109.25 -1.03997 130.8 -1.68997 171.53 0.910029C191.38 2.18003 211.78 4.59003 230.54 13.65C258.58 27.2 279.35 53.69 298.82 79.08C320.85 107.81 343.49 137.75 352.77 171.96C368.97 231.65 338.55 291.9 290.59 320.48C239.57 350.88 169.71 349.7 112.32 317.45C54.9398 285.2 11.9798 222.99 2.70984 158.72C0.259842 141.75 0.0498419 124.45 4.61984 108.83C14.9898 73.34 63.7498 35.54 63.7498 35.54Z" fill="#011E41"/>
          <path d="M123.421 140.53V167.37H111.601V142.17C111.601 137.08 108.231 134.7 104.461 134.7C100.351 134.7 96.0905 137.74 96.0905 144.22V167.37H84.2705V125.18H95.7605V130.27C98.3005 126.41 103.071 124.19 108.401 124.19C116.611 124.19 123.421 129.36 123.421 140.52V140.53Z" fill="white"/>
          <path d="M171.271 150.05H142.871C143.611 154.07 147.381 157.6 153.461 157.6C157.811 157.6 161.501 155.63 163.971 152.84L169.961 160.23C165.281 165.16 159.451 167.86 152.231 167.86C139.011 167.86 130.641 158.83 130.641 146.36C130.641 133.89 139.261 124.2 152.471 124.2C164.371 124.2 171.271 131.92 171.271 145.62V150.05ZM159.531 141.6C159.531 137.25 156.821 133.88 151.651 133.88C146.811 133.88 143.521 136.84 142.621 141.6H159.531Z" fill="white"/>
          <path d="M192.28 167.87C184.65 167.87 178.49 162.78 178.49 153.18V110H190.31V151.94C190.31 155.31 192.44 157.28 195.24 157.28C196.47 157.28 197.87 156.87 199.18 156.13L201.97 165.24C198.69 166.96 195.4 167.87 192.28 167.87Z" fill="white"/>
          <path d="M219.28 167.87C211.65 167.87 205.49 162.78 205.49 153.18V110H217.31V151.94C217.31 155.31 219.44 157.28 222.23 157.28C223.46 157.28 224.86 156.87 226.17 156.13L228.96 165.24C225.68 166.96 222.39 167.87 219.27 167.87H219.28Z" fill="white"/>
          <path d="M274.191 125.18V164.83C274.191 178.29 265.081 186.66 251.951 186.66C241.691 186.66 233.481 180.92 231.021 172.87L241.531 168.19C242.841 172.54 246.041 175.99 252.281 175.99C258.521 175.99 262.291 172.38 262.291 165.97V159.98C260.161 164.25 255.56 166.63 249.65 166.63C240.78 166.63 233.891 161.13 233.891 150.05V125.18H245.79V148.08C245.79 153.25 248.831 156.04 253.421 156.04C258.431 156.04 262.291 152.76 262.291 146.52V125.18H274.191Z" fill="white"/>
          <path d="M128.47 210.66C128.47 222.81 121.08 232.49 108.69 232.49C102.61 232.49 97.4402 230.03 95.1502 226.25V231.99H83.9902V174.53H95.8102V194.8C97.8602 191.19 102.54 188.81 108.61 188.81C121.17 188.81 128.47 198.58 128.47 210.64V210.66ZM116.57 210.66C116.57 204.17 112.71 199.25 106.31 199.25C99.9102 199.25 95.8002 203.93 95.8002 210.66C95.8002 217.39 99.9902 221.99 106.31 221.99C112.63 221.99 116.57 217.39 116.57 210.66Z" fill="white"/>
          <path d="M177.05 210.66C177.05 223.13 167.69 232.49 155.05 232.49C142.41 232.49 133.13 223.13 133.13 210.66C133.13 198.19 142.41 188.83 155.05 188.83C167.69 188.83 177.05 198.19 177.05 210.66ZM144.96 210.66C144.96 217.31 148.9 221.91 155.06 221.91C161.22 221.91 165.16 217.31 165.16 210.66C165.16 204.01 161.22 199.41 155.06 199.41C148.9 199.41 144.96 204.01 144.96 210.66Z" fill="white"/>
          <path d="M225.641 210.66C225.641 223.13 216.281 232.49 203.641 232.49C191.001 232.49 181.721 223.13 181.721 210.66C181.721 198.19 191.001 188.83 203.641 188.83C216.281 188.83 225.641 198.19 225.641 210.66ZM193.551 210.66C193.551 217.31 197.491 221.91 203.651 221.91C209.811 221.91 213.751 217.31 213.751 210.66C213.751 204.01 209.811 199.41 203.651 199.41C197.491 199.41 193.551 204.01 193.551 210.66Z" fill="white"/>
          <path d="M245.26 225.19C245.26 229.21 242.14 232.5 237.63 232.5C233.12 232.5 230 229.22 230 225.19C230 221.16 233.04 217.97 237.63 217.97C242.22 217.97 245.26 221.17 245.26 225.19Z" fill="white"/>
        </svg>
      </div>
    </div>
  </div>
</template>
