<template>
  <div class="wrap">
    <app-header />
    <div class="main">
      <div class="main__inner container">
        <newsletter />
      </div>
    </div>
    <app-footer />
  </div>
</template>
<script setup>
import Newsletter from './components/Newsletter.vue';
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
</script>
