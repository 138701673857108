<template>
  <svg viewBox="0 0 16 16" width="16" height="16">
    <g stroke-width="1" fill="currentColor" stroke="currentColor">
      <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
            x1="11.5" y1="4.5" x2="4.5" y2="11.5"></line>
      <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
            x1="4.5" y1="4.5" x2="11.5" y2="11.5"></line>
    </g>
  </svg>
</template>
