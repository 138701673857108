<template>
  <div class="footer">
    <button @click.prevent="termsActive = true">Terms &amp; Conditions</button>

    <div v-if="termsActive" class="footer__terms">
      <button class="footer__terms-close" @click.prevent="termsActive = false">
        <icon-close />
      </button>
      <div class="footer__terms-inner container">
        <div class="content">
          <h2>Terms and Conditions:</h2>
          <p>
            Sign up to join the Nelly Boo Family email newsletter at the Baby Show on Saturday 11 or Sunday 12 March 2023 and you will automatically
            go in the draw to win <strong>1 of 3 $150 vouchers</strong>.
          </p>
          <p>
            <strong>Three winners</strong> will be selected by random draw on Monday 13 March and contacted via the email address submitted. If the
            <strong>winner does not reply to the email within 5 working days, the prize will be forfeited</strong> and an alternate winner will be
            selected. There are <strong>3 prizes in total</strong> comprising of $150 Nelly Boo store credit. The total prize pool value will be $450
            (NZD) maximum. By entering your email address you agree to receive emails from Nelly Boo including, but not limited to – offers,
            promotions, news and updates. You can unsubscribe at any time by clicking the link in the footer of our emails. Nelly Boo reserves the
            right to modify or cancel this promotion at any time without notice.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import IconClose from './IconClose.vue';

const termsActive = ref(false);
</script>
